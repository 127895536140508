import { useCallback } from 'react';

import Logo from 'assets/svgs/Logo_Vialaser.svg';
import HappyLisa from 'assets/videos/happyLisa.webm';
import {
  useLocale,
  useNavigation,
  useRouteParams,
} from 'shared/presentation/contexts';
import { useInteractionTimeout } from 'shared/presentation/hooks';

import { BannerModal } from './components';
import * as S from './styles';

const TIMEOUT = 45;

const Thank: React.FC = () => {
  const navigation = useNavigation();
  const { name } = useRouteParams<'/thank/:name'>();
  const { t } = useLocale('translations');

  useInteractionTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => navigation.push('/'), [navigation]),
  });

  const finishCheckinRedirect = (): void => {
    return navigation.push('/');
  };

  const alt = t('common.logo_alt');

  return (
    <>
      <S.Container>
        <S.Logo>
          <img src={Logo} height="100" alt={alt} />
        </S.Logo>
        <main>
          <S.Balloon onClick={finishCheckinRedirect}>
            <S.Title>{t('pages.checkin.success.title', { name })}</S.Title>
            <S.SubTitle>{t('pages.checkin.success.subtitle')}</S.SubTitle>
          </S.Balloon>
          <S.Video src={HappyLisa} autoPlay loop muted />
        </main>
      </S.Container>

      <BannerModal />
    </>
  );
};

export default Thank;
